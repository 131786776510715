<template>
  <div class="hero-body is-justify-content-center">
    <b-loading :active.sync="invisible" :can-cancel="false"></b-loading>
    <div v-if="reviewMode" class="container summary-wrap">
      <h1 class="has-text-centered title">
        {{ title }}
      </h1>
      <component
        :ref="`ref${question.code}`"
        :key="question.qid"
        v-for="question in questions"
        :is="`Q${question.type}`"
        :lang="lang"
        :loading="loading"
        :question="question"
        :show-question-code="showQuestionCode"
        :show-answer-code="showAnswerCode"
        :value="response.result"
        :sid="survey.sid"
        :respondent="response"
        :result="response.result"
        :reviewMode="true"
        class="mb-5"
        @edit="editQuestion(question)"
      ></component>
    </div>
    <div v-else>
      <section class="section">
        <ValidationObserver
          class="container"
          ref="surveyPreviewSubmitObserver"
          tag="div"
        >
          <form
            @keypress.enter.exact.prevent
            @submit.prevent="submitQuestion"
            id="survey"
          >
            <transition-group
              tag="div"
              name="page-component"
              mode="out-in"
              :class="{'opacity-0': invisible}"
              class="columns page-wrap is-centered is-vcentered">
              <component
                :key="editingQuestion.qid"
                :is="`Q${editingQuestion.type}`"
                :lang="lang"
                :question="editingQuestion"
                :show-question-code="showQuestionCode"
                :show-answer-code="showAnswerCode"
                :value="changes"
                :sid="survey.sid"
                :respondent="response"
                :result="response.result"
                @ready="(payload) => invisible = !payload"
                @mounted="syncWith"
                @input="onChange"
              ></component>

              <div class="column form-submit" :class="navWidth" :key="`submit-${editingQuestion.qid}`">
                <b-button
                  class="mr-2"
                  native-type="submit"
                  type="is-primary"
                >
                  {{ $t('btn.next') }}
                </b-button>
              </div>
            </transition-group>
          </form>
        </ValidationObserver>
      </section>
    </div>
    <div class="summary-footer is-flex is-align-content-center is-justify-content-space-between has-text-centered">
      <div class="logo is-flex is-align-items-center">
        <span class="powered-by" v-html="$t('powered_by')"></span>
      </div>
      <div v-if="reviewMode" class="nav is-flex is-align-content-center">
        <b-button
          @click="$emit('submit')"
          type="is-primary"
        >
          {{ $t('btn.submit') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { SurveyLayoutMixin } from '@/mixins/survey-layout'
import { QuestionTypeSummaryComponentsMixin } from '@/mixins/question-type-summary-components'
import { UPDATE_RESPONSE } from '../store'
import _pickBy from 'lodash.pickby'
export default {
  name: 'Summary',
  mixins: [
    SurveyLayoutMixin,
    QuestionTypeSummaryComponentsMixin
  ],
  props: {
    lang: {
      type: String,
    },
    title: {
      type: String,
    },
    survey: {
      type: Object,
      default: () => ({}),
    },
    response: {
      type: Object,
      default: () => ({}),
    },
    showQuestionCode: {
      type: Boolean,
    },
    showAnswerCode: {
      type: Boolean,
    },
  },
  data () {
    return {
      loading: true,
      reviewMode: true,
      editingQuestion: null,
      changes: {},
      nextQuestions: [],
      invisible: true
    }
  },
  computed: {
    questions() {
      return this.$store.getters.previewedQuestions(this.survey.code);
    },
  },
  methods: {
    ...mapActions([UPDATE_RESPONSE]),
    setEditingQuestion (question) {
      this.editingQuestion = question
      this.changes = _pickBy(this.response.result, (index, key)=> {
        return Number.parseInt(key.split('_')?.[0]) === question.qid
      })
    },
    editQuestion (question) {
      this.setEditingQuestion(question)
      this.reviewMode = false
    },
    async onChange (data) {
      this.changes = { ...this.changes, ...data }
    },
    async submitQuestion () {
      const isValid = await this.$refs.surveyPreviewSubmitObserver.validate()
      if (!isValid) return
      const data = await this[UPDATE_RESPONSE]({
        code: this.survey.code,
        data: { response_id: this.response._id, result: this.changes },
      })
      this.nextQuestions = [ ...this.nextQuestions, ...data ]
      if (!this.nextQuestions.length) this.reviewMode = true
      else {
        this.setEditingQuestion(this.nextQuestions[0])
        this.nextQuestions = this.nextQuestions.slice(1)
      }
    }
  },
  mounted () {
    this.invisible = false
  }
}
</script>

<style scoped>
.summary-footer {
  position: fixed;
  bottom: 0;
  left: 15px;
  right: 15px;
  background-color: #fff;
  margin: 0;
  padding: 1rem;
}
.powered-by {
  font-size: 0.875rem;
  font-weight: 400;
  color: #bdbdbd;
}
</style>
