export const QuestionTypeSummaryComponentsMixin = {
  components: {
    ErrorMessage: () => import("@/components/summary/ErrorMessage"),
    QSA: () => import("@/components/summary/SingleAnswer"),
    QMA: () => import("@/components/summary/MultipleAnswer"),
    QFA: () => import("@/components/summary/FreeAnswer"),
    QNI: () => import("@/components/summary/NumberInput"),
    QSAM: () => import("@/components/summary/SingleAnswerMatrix"),
    QDL: () => import("@/components/summary/DropdownList"),
    QS: () => import("@/components/summary/Satisfaction"),
    QDT: () => import("@/components/summary/DateTimePicker"),
    QMI: () => import("@/components/summary/MultipleInput"),
    QFU: () => import("@/components/summary/FileUpload"),
    QR: () => import("@/components/summary/Ranking"),
    QLL: () => import("@/components/summary/LatLong"),
    QMAM: () => import("@/components/summary/MultipleAnswerMatrix"),
  },
};
